import { TemperatureRecordingInformation } from './temperature-recordings.model';
import { Shipment } from '@core/models/shipment.model';

export class SetShipment {
  public static readonly type = '[Temperature recordings] Set scanned shipment';

  constructor(
    public shipment: Shipment,
    name?: string,
    order?: string,
  ) {}
}

export class SetTemperatureRecordingInformation {
  public static readonly type = '[Temperature recordings] Set information';

  constructor(public information: TemperatureRecordingInformation[]) {}
}

export class SetBackUrl {
  public static readonly type = '[Temperature recordings] Set back url';

  constructor(public backUrl: string) {}
}
